<template>
  <div class="form">
    <!-- 组3 -->
    <div class="weui-cells">
      <apicker
        :options="organization"
        text="所属机构"
        :model.sync="obj.institution"
        placeholder="请选择"
        :required="true"
      ></apicker>
      <apicker
        :options="countries"
        text="国别公司"
        :model.sync="obj.country"
        placeholder="请选择"
        v-if="judgement() && countries.length"
      ></apicker>
      <apicker
        :options="projects"
        text="所属项目"
        :model.sync="obj.project"
        placeholder="请选择"
        v-if="judgement() && projects.length"
      ></apicker>
    </div>
  </div>
</template>

<script>
import apicker from "@/components/form/picker";
import { getOrganization } from "@/api/search";

export default {
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    global: {
      type: Boolean,
      default: false,
    },
    append:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      name: "",
      obj: {
        institution: "",
        country: "",
        project: "",
      },
      organization: [],
      treeData: [],
    };
  },
  components: {
    apicker,
  },
  mounted() {
    console.log(this.form)
    this.getOptions({flag:1});
  },

  methods: {
    judgement() {
      return this.obj.institution && this.obj.institution.indexOf("总部") < 0;
    },
    async getOptions(query) {
      let res = await getOrganization(query);
      this.treeData = res.data.data;
      this.organization = res.data.data.map((item) => {
        return {
          label: item.name,
          value: item.name,
        };
      });
      this.append&&this.organization.unshift({ label: "中建国际", value: "" });
      console.log(this.form.country)
      this.obj = {
        institution: this.form ? this.form.institution : "",
        country: this.form ? this.form.country : "",
        project: this.form ? this.form.project : "",
      };
     
    },
  },
  computed: {
    countries() {
      let val = this.obj.institution;
      try {
        if (this.treeData.some((item) => item.name == val)) {
          let arr = this.treeData
            .find((item) => item.name === val)
            .secOrganizes.map((item) => {
              return {
                label: item.name,
                value: item.name,
              };
            });
          if (!this.global) this.obj.country = arr.length ? arr[arr.length - 1].label : "";
          return arr;
        }
      } catch (e) {}
      return [];
    },
    projects() {
      console.log(this.obj.country,2)
      let val = this.obj.country;
      console.log(val)
      try {
        let result = this.treeData.find(
          (item) => item.name == this.obj.institution
        ).secOrganizes;
        if (result.some((item) => item.name === val)) {
          let arr = result
            .find((item) => item.name == val)
            .secOrganizes.map((item) => {
              return {
                label: item.name,
                value: item.name,
              };
            });
          if (!this.global) this.obj.project = arr[0] ? arr[0].label : "";
          return arr;
        }
      } catch (e) {}
      return [];
    },
  },
  watch: {
    obj: {
      deep: true,
      handler: function (val) {
        this.$emit("change", this.obj);
      },
    },
  },
};
</script>
<style>
</style>