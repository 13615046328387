<template>
  <section>
    <common-switch @change="change" :global="true" append></common-switch>
    <div class="btn-area">
      <button class="weui-btn weui-btn_primary" @click="submit" >确认</button>
    </div>
  </section>
</template>

<script>
import commonSwitch from "@/components/common/organization";
export default {
  data() {
    return {
      form: {},
    };
  },
  components: {
    commonSwitch,
  },
  methods: {
    submit() {
      this.$store.commit("SET_PROJECT_FORM", this.form);
      this.$router.go(-1);
    },
    change(e) {
      this.form = e;
    },
  },
};
</script>

<style>
</style>